import React from 'react';
import { useNavigate } from 'react-router-dom';
const IS1 = require('../marche_aur/IS1.png');
const IS2 = require('../marche_aur/IS2.png');

export default function Suini() {

  let navigate = useNavigate();

  return (
  
    <div className=''>
      <div className="bg-suini bg-cover w-full h-[480px] "></div>
      <div className="pl-[10px] xl:pl-[170px] bg-stone-200">
      <h1 className="pt-[50px] text-[35px] font-bold font-sans">MARCHE MINISTERIALI PER SUINI</h1>
      <h3 className="text-[20px] font-semibold font-sans"><br></br><br></br>Fornitori ufficiali di Marche Ministeriali per l'idenficazione animale di allevamenti bovini. Codice Fornitore 513.</h3>
    
    <div className='h-[1000px] xl:h-[500px] 2xl:h-[700px] grid grid-rows-2 xl:grid-cols-2 gap-[100px] xl:gap-[300px] 2xl:gap-[220px] 2xl:pl-[300px] p-[30px] justify-center xl:pr-[200px] xl:pl-[80px] pt-[100px]'>

      <div className="hover:bg-neutral-500/70 duration-700 flex items-center w-[350px] h-[350px] xl:w-[250px] xl:h-[250px] 2xl:w-[350px] 2xl:h-[350px]">
        <img src={IS1} alt="" className="" />
        <h1 className="absolute pb-3 pt-[300px] pl-[160px] font-bold text-[22px] xl:pt-[200px]">IS1</h1>
      </div>
      <div className="hover:bg-neutral-500/70 duration-700 flex items-center w-[350px] h-[350px] xl:w-[250px] xl:h-[250px] 2xl:w-[350px] 2xl:h-[350px]">
        <img src={IS2} alt="" className="" />
        <h1 className="absolute pb-3 pt-[300px] pl-[150px] font-bold text-[22px] xl:pt-[200px]">IS2</h1>
      </div>
    </div>
    </div>
    </div>
  );
}