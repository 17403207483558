import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
const negozio = require('../foto/foto_negozio.png');

export default function Mappa() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBGryiKJg--NcI1Jz5IYOo1zF_aAYEQM50",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 38.15629017125705, lng: 15.205182284804279 }), []);

  return (
    <div className="bg-gradient-to-b from-slate-500/50 to-slate-500 ">
    <div className="bg-cover w-full h-[600px]">
    <h1 className="pt-[150px] text-[35px] font-bold font-mono text-center text-gray-700">DOVE CI TROVIAMO...</h1>
    <div className="flex justify-center">
      <img src={negozio} className='w-[1500px] h-[650px] pt-[50px] rounded-t-2xl rounded-b-2xl'></img>
    </div>
    </div>
    <div className="h-[1000px] w-full pt-[400px] flex justify-center">
    <GoogleMap zoom={16} center={center} mapContainerClassName="w-[800px] h-[450px]">
      <Marker position={center} />
    </GoogleMap>
    </div>
    </div>
  );
}