import React from "react";
import Section from "../Section";
import Section2 from "../Section2";
import Section_icons from "../Section_icons";
import Section_video from "../Section_video";
import Mappa from "../Mappa";
import Section_servizi from "../Section_servizi";
import Bottone from "../Bottone";


export default function Home() {
    
  return (
    <div className="bg-stone-200" id='home'>
    <Section/>
    <Section2/>
    <Section_icons/>
    <Bottone/>
    <Section_video/>
    <Section_servizi/>
    <Mappa/>
    </div>
  );
}