import React from "react";
import { useNavigate } from 'react-router-dom';
const I09 = require('../marche_aur/I09.png');
const I10 = require('../marche_aur/I10.png');
const I15 = require('../marche_aur/I15.png');
const I16 = require('../marche_aur/I16.png');



export default function Ovini() {

  let navigate = useNavigate();

  return (
  
    <div className=''>
      <div className="bg-ovini bg-cover w-full h-[480px] "></div>
      <div className="pl-[10px] xl:pl-[170px] bg-stone-200/70">
      <h1 className="pt-[50px] text-[35px] font-bold font-sans">MARCHE MINISTERIALI PER OVINI</h1>
      <h3 className="text-[20px] font-semibold font-sans"><br></br><br></br>Fornitori ufficiali di Marche Ministeriali per l'idenficazione animale di allevamenti bovini. Codice Fornitore 513.</h3>
    
    <div className='xl:h-[500px] 2xl:h-[700px] grid grid-rows-4 xl:grid-cols-4 p-[30px] xl:p-0 pt-[100px] xl:pt-[150px] gap-[50px] xl:gap-0 justify-center'>

      <div onClick={()=> navigate('/i09')} className="hover:bg-neutral-500/70 duration-700 flex items-end cursor-pointer w-[300px] h-[300px] xl:w-[250px] xl:h-[250px] 2xl:w-[300px] 2xl:h-[300px]">
        <img src={I09}/>
        <h1 className="absolute pb-3 pl-[140px] font-bold text-[22px]">I09</h1>
      </div>
      <div onClick={()=> navigate('/i10')} className="hover:bg-neutral-500/70 duration-700 cursor-pointer flex w-[300px] h-[300px] xl:w-[250px] xl:h-[250px] 2xl:w-[300px] 2xl:h-[300px]">
        <img src={I10} alt="" className="" />
        <h1 className="absolute pt-[255px] pl-[135px] xl:pt-[200px] 2xl:pt-[255px] font-bold text-[22px]">I10</h1>
      </div>
      <div onClick={()=> navigate('/i15')} className="hover:bg-neutral-500/70 duration-700 cursor-pointer flex items-center w-[300px] h-[300px] xl:w-[250px] xl:h-[250px] 2xl:w-[300px] 2xl:h-[300px]">
        <img src={I15} alt="" className="" />
        <h1 className="absolute pt-[230px] pl-[135px] xl:pt-[180px] 2xl:pt-[230px] font-bold text-[22px]">I15</h1>
      </div>
      <div onClick={()=> navigate('/i16')} className="hover:bg-neutral-500/70 duration-700 cursor-pointer flex items-center w-[300px] h-[300px] xl:w-[250px] xl:h-[250px] 2xl:w-[300px] 2xl:h-[300px]">
        <img src={I16} className=''/>
        <h1 className="absolute pt-[225px] pl-[135px] xl:pt-[175px] 2xl:pt-[225px] font-bold text-[22px]">I16</h1>
    </div>
    </div>
    </div>
    </div>
  );
}