import React, {useState} from "react";
import "./Modal.css";
import { useNavigate } from 'react-router-dom';
const I01 = require('../marche_aur/I01_giallo.png');
const I01_salmone = require('../marche_aur/I01_salmone.png');
const I02 = require('../marche_aur/I02.png');

export default function Bovini() {

  const [modal, setModal] = useState(false);
  let navigate = useNavigate();

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
  
    <div className=''>
      <div className="bg-bovini bg-cover w-full h-[480px] "></div>
      <div className="p-[10px] xl:pl-[170px] bg-stone-200">
      <h1 className="pt-[50px] text-[35px] font-bold font-sans">MARCHE MINISTERIALI PER BOVINI</h1>
      <h3 className="text-[20px] font-semibold font-sans"><br></br><br></br>Fornitori ufficiali di Marche Ministeriali per l'idenficazione animale di allevamenti bovini. Codice Fornitore 513.</h3>
    
    <div onClick={()=> navigate('/i01')} className='xl:h-[500px] 2xl:h-[700px] grid grid-rows-3 xl:grid-cols-3 gap-[50px] xl:gap-[150px] xl:pl-[50px] 2xl:pl-[120px] p-[30px] justify-center xl:pr-[300px] pt-[100px]'>
      <div className="hover:bg-neutral-500/70 duration-700 flex items-end w-[350px] h-[350px] xl:w-[250px] xl:h-[250px] 2xl:w-[350px] 2xl:h-[350px]">
        <img src={I01} alt="" className="" />
        <h1 className="absolute pb-3 pl-[160px] font-bold text-[22px]">I01</h1>
      </div>
      <div onClick={()=> navigate('/i01')} className="hover:bg-neutral-500/70 duration-700 flex items-end w-[350px] h-[350px] xl:w-[250px] xl:h-[250px] 2xl:w-[350px] 2xl:h-[350px]">
        <img src={I01_salmone} alt="" className="" />
        <h1 className="absolute pb-3 pl-[107px] font-bold text-[22px]">I01-salmone</h1>
      </div>
      <div onClick={()=> navigate('/i02')} className="hover:bg-neutral-500/70 duration-700 flex items-end w-[350px] h-[350px] xl:w-[250px] xl:h-[250px] 2xl:w-[350px] 2xl:h-[350px]">
        <img src={I02} alt="" className="" />
        <h1 className="absolute pb-3 pl-[160px] font-bold text-[22px]">I02</h1>
      </div>
    </div>
    </div>
    </div>
  );
}