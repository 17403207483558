import React from "react";
import { BsInstagram, BsFacebook } from "react-icons/bs";

export default function Footer(){

    return(

        <div className="grid grid-cols-3 gap-[60px] xl:gap-0 pl-[10px] md:pl-[100px] xl:pl-[300px] pt-[50px] bg-slate-500/40 w-full h-[300px]" id='contatti'>

            <div>
                <h1 className="font-bold text-[15px] xl:text-[20px]">Indirizzo</h1>
                <h3 className="pt-[30px] font-semibold text-[12px] xl:text-[15px]">Corso Sicilia, 43<br></br>98051 Barcellona pozzo di Gotto ME</h3>
            </div>
            <div>
                <h1 className="font-bold text-[15px] xl:text-[20px] pb-[20px]">Contatti</h1>
                <h1 className="font-semibold text-[12px] xl:text-[15px]">email: catalanozootec@gmail.com</h1>
                <h1 className="font-semibold text-[12px] xl:text-[15px]">reparto marche: +39 331 809 8020</h1>
                <h1 className="font-semibold text-[12px] xl:text-[15px]">segreteria: +39 377 398 6053</h1>

            </div>
            <div>
                <h1 className="font-bold text-[15px] xl:text-[20px] pb-[20px]">Social</h1>
                <div className="flex gap-7">
                <BsInstagram className="cursor-pointer" size={26} onClick={()=>window.open('https://www.instagram.com/catalanozootech/')}/>
                <BsFacebook className="cursor-pointer" size={26} onClick={()=>window.open('https://www.facebook.com/CatalanoZootech')}/>
                </div>
            </div>
        </div>
    );
}

