import React from 'react';
const video = require('./foto/video_veloce_noaudio.mp4');

const Section = () => {
  return (

    <div className='flex justify-center xl:h-[450px] pt-[150px]'>
    <div className="grid grid-rows-2 xl:grid-cols-2 gap-[80px] 2xl:gap-[50px] xl:pb-[300px]">
      <div className='flex justify-center xl:pl-[400px]'>
      <iframe width="410" height="240" src={video} title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-pictures" allowFullScreen></iframe>
      </div>
      <div className="text-gray-700 font-semibold text-center xl:pr-[400px]"><h1 className='text-[25px]'>Di cosa ci occupiamo?</h1><br></br><br></br>La nostra azienda si occupa principalmente<br></br>della realizzazione di marche auricolari.<br></br>Nel video qui accanto la realizzazione di una<br></br>marca auricolare marcata Catalano Zootech.</div>
    </div>
    </div>
  );
};

export default Section;