import React from "react";
import { useNavigate } from 'react-router-dom';
const ORDINE = require('../foto/foto_ordine.jpeg');
const screen1 = require('../foto/SCREEN1.png');
const screen2 = require('../foto/SCREEN2.png');
const screen3 = require('../foto/SCREEN3.png');
const screen4 = require('../foto/SCREEN4.png');

export default function Come_sceglierci(){

    let navigate = useNavigate();

    return(

        <div>
            <div className="bg-marche_sfondo bg-cover w-full h-[480px] "></div>
            <div className="p-[20px] xl:pl-[170px] h-[350px] w-full pt-[150px]">
            <h1 className="text-[35px] font-bold font-sans">COME CREARE IL TUO ORDINE</h1>
            <h3 className="text-[20px] font-semibold font-sans"><br></br><br></br>Come scegliere la nostra azienda sul portale "Banca Dati Nazionale" <a href="https://www.vetinfo.it/" className="text-blue-500">vetinfo.it</a></h3>
            </div>
            <div className="pb-[150px] pt-[100px] lg:pt-0 flex justify-center">
                <img src={screen1} className='flex justify-center px-[30px] border-gray-300 border-2 rounded-2xl'/>
            </div>

            <div className="pb-[150px]">
                <h1 className="text-[20px] font-semibold font-sans xl:pl-[170px] p-[20px]">Successivamente cliccare sulla griglia come indicato di seguito.<br></br><br></br></h1>
                <div className="flex justify-center">
                <img src={screen2} className='pt-[50px] flex justify-center'/>
                </div>
            </div>

            <div className="pb-[150px]">
                <h1 className="text-[20px] font-semibold font-sans xl:pl-[170px] p-[20px]">Una volta giunto in questa pagina puoi cliccare sulla dicitura 'identificativi prodotti' per visualizzare i nostri prodotti.<br></br><br></br></h1>
                <div className="flex justify-center">
                <img src={screen3} className='pt-[50px] pl-[30px] flex justify-center'/>
                </div>
            </div>

            <div className="pb-[150px]">
                <h1 className="text-[20px] font-semibold font-sans xl:pl-[170px] p-[20px]">Di seguito il nostro catalogo. Scegli il modello piu' adatto alla tua azienda.<br></br><br></br><br /></h1>

            <div>
            <h1 className="text-[20px] font-sans p-[20px] flex justify-center text-orange-600 font-bold">Catalogo Bovini e Bufali</h1>
            </div>
            

            <div className="pt-[30px] w-full overflow-auto lg:flex justify-center">

                <table className="w-[1200px]">
                    <thead className="bg-gray-200 border-2 border-gray-200">

                        <tr>

                            <th>CODICE PRODUTTORE</th>
                            <th>PRODUTTORE IDENTIFICATIVI</th>
                            <th>SPECIE</th>
                            <th>CODICE MARCA</th>
                            <th>TIPO MARCA</th>
                            <th>TIPOLOGIA MARCA</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i01')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">BOVINI E BUFALI</th>
                        <th className="text-blue-500 font-bold">I01</th>
                        <th className="font-semibold">IB 010151 OS ID -LARGE PLUS- MARCA CONVENZIONALE TRAPEZOIDALE<br></br>2M+2F MARCA AUR. BOVINI E BUFALINI</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-200 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i02')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">BOVINI E BUFALI</th>
                        <th className="text-blue-500 font-bold">I02</th>
                        <th className="font-semibold">IB 02051 COMBI 2000 LARGE- MARCA CONVENZIONALE TRAPEZOIDALE<br></br>2M+2F MARCA AUR. BOVINI E BUFALINI</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i04')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">BOVINI E BUFALI</th>
                        <th className="text-blue-500 font-bold">I04</th>
                        <th className="font-semibold">IB 13051 OS ID -TST- MARCA BIOPTICA TRAPEZOIDALE<br></br>2M+2F PRELIEVO SINGOLO MARCA AUR. BOVINI E BUFALINI</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                </table>
            </div>

            <div className="pt-[120px]">
            <h1 className="text-[20px] font-sans p-[20px] flex justify-center text-orange-600 font-bold">Catalogo Ovini e Caprini</h1>
            </div>

            <div className="pt-[30px] w-full overflow-auto lg:flex justify-center">
                <table className="w-[1200px]">
                    <thead className="bg-gray-200 border-2 border-gray-200">

                        <tr>

                            <th>CODICE PRODUTTORE</th>
                            <th>PRODUTTORE IDENTIFICATIVI</th>
                            <th>SPECIE</th>
                            <th>CODICE MARCA</th>
                            <th>TIPO MARCA</th>
                            <th>TIPOLOGIA MARCA</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i09')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">OVINI E CAPRINI</th>
                        <th className="text-blue-500 font-bold">I09</th>
                        <th className="font-semibold">IO 05051 COMBI MINI <br /> CODICE BDN I09</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-200 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i10')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">OVINI E CAPRINI</th>
                        <th className="text-blue-500 font-bold">I10</th>
                        <th className="font-semibold">IO 06051 COMBI BUTTON <br /> CODICE BDN I10</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i15')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">OVINI E CAPRINI</th>
                        <th className="text-blue-500 font-bold">I15</th>
                        <th className="font-semibold">IO 04052 OS ID SMALL <br /> CODICE BDN I15</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-200 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i16')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">OVINI E CAPRINI</th>
                        <th className="text-blue-500 font-bold">I16</th>
                        <th className="font-semibold">IO 06052 OS ID BUTTON <br /> CODICE BDN I16</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                </table>
            </div>

            <div className="pt-[120px]">
            <h1 className="text-[20px] font-sans p-[20px] flex justify-center text-orange-600 font-bold">Catalogo Suini</h1>
            </div>

            <div className="pt-[30px] w-full overflow-auto lg:flex justify-center">
                <table className="w-[1200px]">
                    <thead className="bg-gray-200 border-2 border-gray-200">

                        <tr>

                            <th>CODICE PRODUTTORE</th>
                            <th>PRODUTTORE IDENTIFICATIVI</th>
                            <th>SPECIE</th>
                            <th>CODICE MARCA</th>
                            <th>TIPO MARCA</th>
                            <th>TIPOLOGIA MARCA</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i09')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">SUINI</th>
                        <th className="text-blue-500 font-bold">IS1</th>
                        <th className="font-semibold">IS 06051 OS ID COMBI BUTTON <br /> MARCA CONVENIONALE ROTONDA 1M + 1F</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>

                    <thead className="bg-gray-50 border-2 border-gray-200 cursor-pointer hover:bg-black/40 duration-500" onClick={()=> navigate('/i09')}>

                        <tr>

                        <th className="text-orange-600 font-bold">513</th>
                        <th className="font-semibold">MARCO CATALANO</th>
                        <th className="font-semibold">SUINI</th>
                        <th className="text-blue-500 font-bold">IS2</th>
                        <th className="font-semibold">IS 06052 OS ID BUTTON MARCA<br /> CONVENIONALE ROTONDA 1M + 1F - PUNTA METALLO</th>
                        <th className="font-semibold">MARCA AURICOLARE</th>

                        </tr>

                    </thead>
                </table>
            </div>
        </div>
    </div>
    );
}