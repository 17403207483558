import { MdClose } from "react-icons/md";
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Link as Scroll} from 'react-scroll';



const MenuItems = ({showMenu,active}) => {

  let navigate = useNavigate();

  return (
                <ul className={active ? 'flex-col fixed flex items-center inset-0 left-1/4 uppercase bg-black/40 backdrop-blur-lg gap-5 justify-center p-4 ab' : 'hidden'}>
                    <MdClose onClick={showMenu} className='cursor-pointer scale-150'/>
                    <li className="bg-black/10 p-4 hover:bg-white/30 transition-color duration-700 rounded-2xl cursor-pointer" onClick={showMenu}><Link to='/chisiamo'>Chi siamo</Link></li>
                    <li className="bg-black/10 p-4 hover:bg-white/30 transition-color duration-700 rounded-2xl cursor-pointer"><Scroll to="section2" spy={true} smooth={true} offset={0} duration={1000} className='tr' onClick={showMenu}>Le nostre marche auricolari</Scroll></li>
                    <li className="bg-black/10 p-4 hover:bg-white/30 transition-color duration-700 rounded-2xl cursor-pointer" onClick={showMenu}><Link to='/'>Servizi</Link></li>
                    <li className="bg-black/10 p-4 hover:bg-white/30 transition-color duration-700 rounded-2xl cursor-pointer"><Scroll to="contatti" spy={true} smooth={true} offset={0} duration={1000} onClick={showMenu}>Contatti</Scroll></li>
                    <li className="bg-black/10 p-4 hover:bg-white/30 transition-color duration-700 rounded-2xl cursor-pointer"><Scroll to="dovecitroviamo" spy={true} smooth={true} offset={0} duration={1000} onClick={showMenu}>Dove ci troviamo</Scroll></li>
                </ul>
  );
};

export default MenuItems;