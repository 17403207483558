import React from 'react';
const Image = require('../foto/marco.webp');

export default function Chisiamo(){

    return(
        <div>
        <div className="bg-chisiamo bg-cover w-full h-[400px] "></div>
        <div className="grid grid-rows-2 xl:grid-cols-2 bg-stone-100 pt-[70px] sm:h-[2000px] md:h-[1550px] lg:h-[1200px] xl:h-[950px] 2xl:h-[1000px] gap-[600px] md:gap-0">

        <div>
            <div className="flex justify-center">
            <h1 className="text-[30px] font-bold">CATALANO ZOOTECH</h1>
            </div>
            <div className='2xl:p-[70px] p-[50px] pb-0 xl:pt-[30px]'>
            <h3 className="font-semibold">La Catalano Zootech è un’impresa siciliana che opera nel campo della Zootecnia.<br></br> Essa nasce dell’idea imprenditoriale di Marco Catalano, dottore in Scienza, Tecnologia e Sicurezza delle Produzioni Animali(zoonomo), iscritto all'albo degli Agrotecnici Laureati, specializzato in sistemi di gestione Agro-Zootecnici, imprenditore nel settore agro zootecnico siciliano ed attualmente studente di medicina veterinaria presso l’università degli studi di Messina.
                La Catalano Zootech si pone l’obbiettivo di mettere in collegamento le aziende zootecniche ed i professionisti del settore, presenti sul territorio regionale e non solo. Nello specifico essa fornisce, oltre che un’ampia gamma di prodotti per la zootecnia, un supporto gestionale su misura alle imprese.
                L’elemento cardine attorno cui ruota l’attività di consulenza svolta dalla Catalano Zootech è un lavoro di rete fatto tra i liberi professionisti presenti su tutto il territorio siciliano. Le competenze messe a disposizione dai nostri tecnici derivano non solo dagli studi universitari ma soprattutto dalle significative esperienze maturate negli anni sul campo accanto agli allevatori.
                La Catalano Zootech vuole quindi fornire ai suoi clienti un aiuto concreto, personalizzato e professionale, della sua competenza e passione, venendo in contro anche alle aziende situate in aree marginali del territorio.
                Garantire prodotti e servizi di qualità rappresenta, per la Catalano Zootech, un elemento imprescindibile affinché le aziende locali possano crescere ed affrontare al meglio le difficoltà che giornalmente il mercato pone loro.
                Un aspetto particolarmente a cuore del titolare è, infatti, quello di ridare fiducia agli allevatori, aiutandoli a svolgere con orgoglio e soddisfazione il proprio lavoro, troppo spesso discriminato e non valorizzato!
                La sede legale, con annesso il nostro showroom, si trova a Barcellona Pozzo di Gotto (Me), in Viale Sicilia 43.
            </h3>
            </div>
        </div>
            <div className="flex justify-center pt-0 xl:pt-[50px] 2xl:pt-0">
            <img className='rounded-sm p-[50px] md:p[70px] xl:p-0 w-[500px] h-[510px] xl:w-[610px] xl:h-[660px] 2xl:w-[760px] 2xl:h-[760px]' src={Image}/>
            </div>
        </div>
        </div>
    );
}