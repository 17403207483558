import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export default function Mappa() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDcvnmz9vFzOwhcn2XkENjRW5jGaO8JNm0",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 38.15629017125705, lng: 15.205182284804279 }), []);

  return (
    <div className="grid grid-rows-2 xl:grid-cols-2 xl:pr-[250px] xl:pl-[250px]" id='dovecitroviamo'>
    <div className="text-center text-gray-700 font-semibold">
        <h1 className="text-[25px]"><br></br><br></br>Dove ci troviamo...</h1><br></br><br></br><br></br>Il nostro negozio e' sito in via corso sicilia, 43 <br></br> nella citta' di Barcellona Pozzo di Gotto (ME). <br></br> Vieni a trovarci per scoprire tutti i nostri prodotti. <br></br>
        Clicca qui per ulteriori info sul nostro negozio.

    </div>
    <div className="h-[430px] xl:h-[300px] w-full flex justify-center">
    <GoogleMap zoom={16} center={center} mapContainerClassName="lg:w-[600px] xl:h-[400px] h-[270px] w-[370px] rounded-md">
      <Marker position={center} />
    </GoogleMap>
    </div>
    </div>
  );
}