import React from 'react';

const Section = () => {
  return (
    <div className='bg-testata1 lg:bg-hero-image w-full h-screen bg-cover flex items-center px-4'>
        <div className='text-start text-white font-extrabold'>
            <h1 className='text-[55px]'>Catalano Zootech</h1>
            <h3>BDN N513</h3>
        </div>
    </div>
  );
};

export default Section;