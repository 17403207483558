import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/pages/Home";
import Bovini from "./components/pages/Bovini";
import Ovini from "./components/pages/Ovini";
import Suini from "./components/pages/Suini";
import Footer from "./components/Footer";
import Chisiamo from "./components/pages/Chisiamo";
import Mappa_pages from './components/pages/Mappa_pages';
import Modal from './components/Modal';
import Come_sceglierci from './components/pages/Come_sceglierci';
import Desc_I01 from './components/pages/Desc_I01';
import Desc_I02 from './components/pages/Desc_I02';
import Desc_i04 from './components/pages/Desc_i04';
import Desc_I09 from './components/pages/Desc_I09';
import Desc_I10 from './components/pages/Desc_I10';
import Desc_I15 from './components/pages/Desc_I15';
import Desc_I16 from './components/pages/Desc_I16';
import Desc_boli from './components/pages/Desc_boli';

function App(){

  return(

    <BrowserRouter>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/bovini" element={<Bovini />}/>
        <Route path="/ovini" element={<Ovini />}/>
        <Route path="/suini" element={<Suini />}/>
        <Route path="/chisiamo" element={<Chisiamo />}/>
        <Route path="/mappa_pages" element={<Mappa_pages />}/>  
        <Route path="/modal" element={<Modal />}/>  
        <Route path='/ordini' element={<Come_sceglierci />}/>
        <Route path='/i01' element={<Desc_I01 />}/>
        <Route path='/i02' element={<Desc_I02 />}/>
        <Route path='/i04' element={<Desc_i04 />}/>
        <Route path='/i09' element={<Desc_I09 />}/>
        <Route path='/i10' element={<Desc_I10 />}/>
        <Route path='/i15' element={<Desc_I15 />}/>
        <Route path='/i16' element={<Desc_I16 />}/>
        <Route path='/boli' element={<Desc_boli />}/>

      </Routes>
      <Footer/>
    </BrowserRouter>
  );

}

export default App;