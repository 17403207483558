import React from "react";
const I01 = require('../foto/i01.png');
const I01dim = require('../foto/i01_dimensioni.png');


export default function Desc_I01(){

    return(
        <div className="h-[1700px]">
            <div className="bg-marche_sfondo bg-cover w-full h-[300px] "></div>
            <div className="grid grid-cols-2 h-[800px] px-[400px]">
                <div className="pt-[120px]"><h1 className="text-[30px] text-orange-500 font-semibold font-sans"> IB 01051 LARGE PLUS <br /> Codice BDN I01</h1>
                <h3 className="text-[12px]"><br /> <br /> <br /> CARATTERISTICHE TECNICHE <br /> <br /> Descrizione: 
Marca auricolare composta da due differenti tipologie di plastica, soft e dura, con aggancio perimetrale, sprone 
cilindrico monolitico rinforzato con punta in metallo, boccola della femmina chiusa in plastica dura. 
A breve disponibile anche con boccola parzialmente chiusa. <br /> <br />
Materiale Polimeri certificati termoplastici, atossici. <br /> <br />
Forma Trapezoidale. <br /> <br />
Punta Sprone Metallo. <br /> <br />
Boccola Chiusa - Plastica dura. <br /> <br />
Distanziatore Distanziatore di ventilazione per una rapida cicatrizzazione dei tessuti post applicazione. <br /> <br />
Superficie Liscia ed esente da bolle o micro-fessure. <br /> <br />
Stampa Tecnologia Laser, in colore nero, non asportabile perché reattivo in profondità. <br /> <br />
Fonts Leggibili per tutta la vita dell’animale. <br /> <br />
Layout Prodotta secondo le indicazioni della normativa in vigore. <br /> <br />
Colore Stabilità nel lungo periodo, oltre la vita media di un animale. <br />
 Giallo e Salmone per i capi iscritti a LL.GG. <br /> <br />
Innocuità La non tossicità dei polimeri impiegati garantisce l’innocuità del prodotto sull’animale. <br /> <br />
Capacità Perforante <br />
L’angolo di tangenza della punta in metallo dello sprone dona alla marca auricolare una elevata capacità 
perforante, qualità utile per la prevenzione di processi infiammatori a carico dell’auricola. <br /> <br />
Ancoraggio <br />
Il sistema di ancoraggio EXTRA System delle due parti, maschile e femminile, non consente la riapertura 
della marca dopo l’applicazione. Un deliberato tentativo di effrazione comporterebbe la rottura della punta del 
maschio, all’interno della boccola della femmina, impedendone il riutilizzo <br /> <br />
Rotazione <br />
Sì, libera rotazione tra maschio e femmina. <br /> <br />
Inviolabilità <br />
Pari al 100% - Tamperproof SUSS. <br /> <br />
Certificazioni <br />
Presso il Ministero della Salute sono depositate tutte le certificazioni di prodotto e le attestazioni di qualità. 
Tali documenti sono visionabili presso la nostra azienda, previa dichiarazione di riservatezza. <br /> <br />
Applicatore <br />
2003000-2002201 - Combi Junior per linea Large Plus.
</h3>
                </div>
                <div className=""><img src={I01} /> <img src={I01dim} className="pl-[160px] pt-[50px]" /> <h1 className="pl-[200px] text-[12px]"><br /> <br /> <br /> Peso
Singola 11g - Doppia 22g <br /> <br />
Distanza
10 mm tra M e F <br />
post applicazione <br /> <br />
Produttore: OS ID</h1></div>
            </div>
        
        </div>

    );

}