import React from "react";
import { useNavigate } from 'react-router-dom';
const bull = require('./foto/bull.png');
const pig = require('./foto/pig.png');
const lamb = require('./foto/lamb.png');


export default function Section_icons(){

    let navigate = useNavigate();

    return(

    <div className="flex justify-center w-full h-[300px]">
        <div className="sm:w-[1050px] w-[800px] flex justify-center">
            <div className="grid grid-cols-3 2xl:h-[400px] gap-[50px] md:gap-[170px] py-[80px] 2xl:pt-0 ">
                <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] flex items-end">
                <img onClick={()=> navigate('/bovini')} className='h-full transform hover:scale-110 duration-700' src={bull}/>
                </div>
                <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] flex items-end">
                <img onClick={()=> navigate('/suini')} className='h-full hover:scale-110 duration-700' src={pig}/>
                </div>
                <div className="w-[100px] h-[100px] md:w-[150px] md:h-[150px] flex justify-center">
                <img onClick={()=> navigate('/ovini')} className='h-full hover:scale-110 duration-700 pt-[15px]' src={lamb}/>
                </div>
            </div>
        </div>
        </div>

    );
}