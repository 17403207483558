import { MdMenu } from "react-icons/md";
import React,{useState} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import {Link as Scroll} from 'react-scroll';
import MenuItems from "./MenuItems";
const Image = require('./foto/logo1.png');

const Header = () => {

    let navigate = useNavigate();

    const [active,setActive] = useState(false)

    const showMenu = () => {
        setActive(!active)
    }

  return (
    <div className='w-screen h-[100px] text-white flex flex-row bg-slate-500/40 fixed'>

        <div className="basis-2/4">
        <div className='h-full pt-2 pb-2 pl-5'>
            <img onClick={()=> navigate('/')} className='h-full cursor-pointer' src={Image}/>
        </div>
        </div>
        
        <div className="basis-2/4 flex items-end p-[50px]">
        <nav className="h-full w-full flex items-center">

            <div className='absolute right-[30px] 2xl:hidden top-6 scale-150'>
                <MdMenu onClick={showMenu} className='scale-150 cursor-pointer'/>
            </div>

                <ul className='hidden 2xl:flex  uppercase w-full h-1/2 justify-between items-center mx-4 gap-[20px]'>
                    <li className="bg-white/10 p-4 hover:bg-white/30 cursor-pointer duration-500 rounded-2xl transform hover:scale-110"><Link to='/chisiamo'>Chi siamo</Link></li>
                    <li className="bg-white/10 p-4 hover:bg-white/30 cursor-pointer duration-500 rounded-2xl transform hover:scale-110"><Scroll onClick={()=> navigate('/')} to="section2" spy={true} smooth={true} offset={0} duration={1000} className='tr'>Le nostre marche auricolari</Scroll></li>
                    <li className="bg-white/10 p-4 hover:bg-white/30 cursor-pointer duration-500 rounded-2xl transform hover:scale-110"><Link to='/'>Servizi</Link></li>
                    <li className="bg-white/10 p-4 hover:bg-white/30 cursor-pointer duration-500 rounded-2xl transform hover:scale-110"><Scroll to="contatti" spy={true} smooth={true} offset={0} duration={1000}>Contatti</Scroll></li>
                    <li className="bg-white/10 p-4 hover:bg-white/30 cursor-pointer duration-500 rounded-2xl transform hover:scale-110"><Scroll onClick={()=> navigate('/')} to="dovecitroviamo" spy={true} smooth={true} offset={0} duration={1000}>Dove ci troviamo</Scroll></li>
                </ul>

                <MenuItems showMenu={showMenu} active={active}/>
        </nav>
        </div>

    </div>
  );
};

export default Header;