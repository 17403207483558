import React from "react";
const boli = require('../foto/foto_boli.png');
const boli2 = require('../foto/boli.png');


export default function Desc_boli(){

    return(
        <div className="h-[1700px]">
            <div className="bg-marche_sfondo bg-cover w-full h-[300px] "></div>
            <div className="grid grid-cols-2 h-[350px] px-[400px]">
                <div className="pt-[120px]"><h1 className="text-[30px] text-orange-500 font-semibold font-sans"> BOLI RUMINALI FDX <br /> Codice BDN IB</h1>
</div> <div className=""> <img src={boli} className='pt-[90px] pl-[30px]' /></div>
            </div>
        <div className="pl-[400px]"> <h3 className="text-[12px]"><br /> <br /> <br /> CARATTERISTICHE TECNICHE <br /> <br /> Descrizione: Bolo Ruminale elettronico ISO11784, FDX/B, HDX, allumina con grado di purezza 96, levigato e bollettato, <br></br>
disponibile in diverse pezzature e grammature. <br></br>
Materiale Allumina 96 - Atossico. <br /> <br />
Transponder Glass Tag 32 mm. <br /> <br />
Inviolabilità <br />
Pari al 100% - Tamperproof SUSS. <br /> <br />
Certificazioni <br />
Presso il Ministero della Salute sono depositate tutte le certificazioni di prodotto e le attestazioni di qualità. <br />
Tali documenti sono visionabili presso la nostra azienda, previa dichiarazione di riservatezza. ICAR - JRC. <br /> <br />
Applicatore <br />
2009102 - Applicatore Boli per Ovini e Caprini. </h3></div>

        <div>
            <img src={boli2} className='pl-[500px] pt-[70px]' alt="" />
        </div>
        </div>

        

    );

}