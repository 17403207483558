import React, { useState } from "react";
import "./Modal.css";
const I01 = require('./marche_aur/I01_giallo.png');

export default function Modal() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <div className="h-[700px] w-full flex justify-center">
      <button onClick={toggleModal} className="btn-modal">
        Open
      </button>

      {modal && (
        <div className="modal flex justify-center pt-[150px]">
          <div onClick={toggleModal} className="overlay"></div>
          
          <div className=" bg-white absolute h-[700px] w-[600px] rounded-xl">
            <div className="bg-black h-[300px] w-[300px]">
          <img src={I01} alt="" className="" />
          </div>
            <h2>OS ID LARGE PLUS</h2>
            <p>
Sul sistema vetinfo la trovate registrata con il nominativo
I01 IB 01051 LARGE PLUS
Marca auricolare OS ID – LARGE PLUS in poliuretano ad elevata flessibilità e resistenza.
La parte maschile, forma trapezoidale, misure 55 x 70 mm, presenta uno sprone cilindrico monolitico rinforzato con punta di metallo.
La parte femminile, forma trapezoidale, misure 55 x 80 mm, presenta una boccola parzialmente chiusa in plastica dura, con distanziatore di ventilazione.
Neutra.
Inviolabile al 100%.
            </p>
            <button className="close-modal" onClick={toggleModal}>
              CLOSE
            </button>
          </div>
        </div>
      )}
    </div>
  );
}