import React from "react";

export default function Section_servizi(){

    return(
        
            <div className="flex justify-center h-[600px] md:h-[350px] w-[full] pb-[500px] px-[50px] xl:px-0">
            <div className="text-center text-gray-700 font-semibold">
            <h1 className="text-[25px]">Servizi</h1><br></br><br></br><br></br>La nostra azienda, identificata con il codice 513 nella Banca Dati Nazionale (BDN), è riconosciuta dal Min. della Salute<br></br> come distributrice di marche auricolari per la specie Bovina, Bufalina, Ovi-Caprina e Suina<br></br>
            ai sensi del D.P.R. 317/96 del D.P.R. 437/2000 e successive modifiche del D.M. 31.01. 2002,  e quelle della circolare del Ministero della Salute 28.07.2005 <br></br> ed autorizzato alla distribuzione di sistemi identificativi.<br></br>
La produzioni riguarda sia sistemi visuali (marche auricolari plastiche) che elettronici (boli endoruminali e marche auricolari elettroniche).<br></br> Le marche auricolari sono: della marca OS ID.<br></br> L’utilizzazione di apparecchiature laser di ultima generazione, permettono tempi di produzioni brevi ed una scrittura duratura nel tempo.</div>
        </div>

    );

}