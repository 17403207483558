import React from "react";
import { useNavigate } from 'react-router-dom';

export default function Bottone(){

    let navigate = useNavigate();

    return(

        <div className="h-[100px] w-full flex justify-center">

            <div onClick={()=> navigate('/ordini')} className="h-[100px] w-[400px] bg-stone-100/80 rounded-2xl text-gray-700 font-semibold text-center hover:scale-110 duration-500 cursor-pointer flex justify-center pb-[110px]">
                <h1 className='text-[25px]'><br></br>Scopri come effettuare un ordine</h1> 
            </div>

        </div>
    );
}