import React from 'react';
import { useNavigate } from 'react-router-dom';

const Section = () => {

  let navigate = useNavigate();

  return (
      <div className='h-[950px] 2xl:h-[400px]' id='section2'>
        <div className='bg-stone-200 grid grid-rows-3 gap-[40px] pt-[35px] md:px-[240px] lg:px-[370px] xl:px-[450px] 2xl:grid-cols-3 2xl:gap-[100px] 2xl:px-[200px] 2xl:pt-0'>
        <div className='bg-stone-100/80 rounded-2xl text-gray-700 font-semibold text-center hover:scale-110 duration-500'>
            <h1 className='text-[25px]'><br></br>Identificazione ministeriale</h1>
            <h3><br></br>Gli identificatori<br></br>marcati Catalano Zootech<br></br>garantiscono un'incisione a laser<br></br>permanente e resistente<br></br>ai raggi UV.<br></br></h3>
        </div>
        <div onClick={()=> navigate('/boli')} className='bg-stone-100/80 cursor-pointer rounded-2xl text-gray-700 font-semibold text-center hover:scale-110 duration-500'>
            <h1 className='text-[25px]'><br></br>Identificazione elettronica</h1>
            <h3><br></br>Realizziamo boli ruminali in capsule <br></br> di ceramica contenenti codici<br></br> identificativi RFID indelebili e<br></br> resistenti alle interferenze<br></br>elettromagnetiche.</h3>
            <br></br>
        </div>
        <div className='bg-stone-100/80 rounded-2xl text-gray-700 font-semibold text-center hover:scale-110 duration-500'>
            <h1 className='text-[25px]'><br></br>Marche aziendali</h1>
            <h3><br></br>Realizziamo marche auricolari <br></br> personalizzate su richiesta<br></br> ed offriamo anche diversi prodotti <br></br>utili per l'identificazione<br></br> aziendale dell'animale.</h3>
            <br></br>
        </div>
        </div>
    </div>
  );
};

export default Section;